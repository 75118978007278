<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale: currentLocale } = useI18n()
const menuVisible = ref(false)

useHead({
  htmlAttrs: {
    lang: currentLocale
  }
})


const openMenu = () => { 
  menuVisible.value = true
};

const closeMenu = () => { 
  menuVisible.value = false
};
</script>

<template>
  <div>
    <div class="wrapAll">
      <MainNavigation @open-menu-mobile="openMenu()" :class="{ open: menuVisible }"/>
      <slot />
    </div>
    <MenuMobile @close-menu-mobile="closeMenu()" :class="{ open: menuVisible }"/>
    <MainFooter />
  </div>
</template>

<style lang="scss">
</style>
