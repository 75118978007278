<script setup lang="ts">
  const localePath = useLocalePath()
  const { t } = useI18n()
  const openAbout = ref(false)
  const openProducts = ref(false)
  const openNews = ref(false)
  const openInvestor = ref(false)
  const openShareholder = ref(false)
  const emit = defineEmits(['closeMenuMobile'])

  function btnClose() {
    emit('closeMenuMobile')
  }
</script>

<template>
  <div class="m menuMobile">
    <div class="toggleMenuOpen">
      <a v-on:click="btnClose">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
      </a>
    </div>
    <div class="menuMobilePrincipal">
      <div class="menuCss7">
        <ul class="">
          <li :class="[{ open: openAbout }, 'item29 first rank0 parent']">
            <NuxtLink :to="localePath('about-about')" @click="openAbout = !openAbout">{{ $t('navigation.about.name') }}</NuxtLink>
            <div class="itemChildDiv29">
              <ul class="itemChild29">
                <li class="item14 first rank0">
                  <NuxtLink :to="localePath('about-corporateProfile')" v-on:click="btnClose">{{ $t('navigation.corporateProfile.name') }}</NuxtLink>
                </li>
                <li class="item15 rank1">
                  <NuxtLink :to="localePath('about-management')" v-on:click="btnClose">{{ $t('navigation.management.name') }}</NuxtLink>
                </li>
                <li class="item12 rank2">
                  <NuxtLink :to="localePath('about-boardDirectors')" v-on:click="btnClose">{{ $t('navigation.boardDirectors.name') }}</NuxtLink>
                </li>
                <li class="item18 last rank3">
                  <NuxtLink :to="localePath('about-facilities')" v-on:click="btnClose">{{ $t('navigation.facilities.name') }}</NuxtLink>
                </li>
              </ul>
            </div>
          </li>
          <li :class="[{ open: openProducts }, 'item9 rank1 parent']">
            <NuxtLink :to="localePath('products')" @click="openProducts = !openProducts">{{ $t('navigation.products.name') }}</NuxtLink>
            <div class="itemChildDiv9">
              <ul class="itemChild9">
                <li class="item22 first rank0">
                  <NuxtLink :to="localePath('products-consumerMarket')" v-on:click="btnClose">{{ $t('navigation.consumerMarket.name') }}</NuxtLink>
                </li>
                <li class="item17 rank1">
                  <NuxtLink :to="localePath('products-awayFromHomeMarket')" v-on:click="btnClose">{{ $t('navigation.awayFromHomeMarket.name') }}</NuxtLink>
                </li>
                <li class="item19 last rank2">
                  <NuxtLink :to="localePath('products-privateLabelMarket')" v-on:click="btnClose">{{ $t('navigation.privateLabelMarket.name') }}</NuxtLink>
                </li>
              </ul>
            </div>
          </li>
          <li :class="[{ open: openNews }, 'item20 rank2 parent']">
            <NuxtLink :to="localePath('newsEvents')" @click="openNews = !openNews">{{ $t('navigation.newsEvents.name') }}</NuxtLink>
            <div class="itemChildDiv20">
              <ul class="itemChild20">
                <li class="item21 first rank0">
                  <NuxtLink :to="localePath('pressReleases')" v-on:click="btnClose">{{ $t('navigation.pressReleases.name') }}</NuxtLink>
                </li>
                <li class="item54 last rank1">
                  <NuxtLink :to="localePath('presentationsWebcasts')" v-on:click="btnClose">{{ $t('navigation.presentationsWebcasts.name') }}</NuxtLink>
                </li>
              </ul>
            </div>
          </li>
          <li :class="[{ open: openInvestor }, 'item30 rank3 parent']">
            <NuxtLink :to="localePath('investorRelations-investorRelations')" @click="openInvestor = !openInvestor">{{ $t('navigation.investorRelations.name') }}</NuxtLink>
            <div class="itemChildDiv30">
              <ul class="itemChild30">
                <li class="item31 first rank0 parent">
                  <NuxtLink :to="localePath('investorRelations-financialDocuments-quarterlyReports')">{{ $t('navigation.financialDocuments.name') }}</NuxtLink>
                  <div class="itemChildDiv31">
                    <ul class="itemChild31">
                      <li class="item11 first rank0">
                        <NuxtLink :to="localePath('investorRelations-financialDocuments-quarterlyReports')" v-on:click="btnClose">{{ $t('navigation.quarterlyReports.name') }}</NuxtLink>
                      </li>
                      <li class="item32 rank1">
                        <NuxtLink :to="localePath('investorRelations-financialDocuments-annualReports')" v-on:click="btnClose">{{ $t('navigation.annualReports.name') }}</NuxtLink>
                      </li>
                      <li class="item52 rank2">
                        <NuxtLink :to="localePath('investorRelations-financialDocuments-presentationsWebcasts')" v-on:click="btnClose">{{ $t('navigation.presentationsWebcasts.name') }}</NuxtLink>
                      </li>
                      <li class="item33 rank3">
                        <NuxtLink :to="localePath('investorRelations-financialDocuments-managementProxyCirculars')" v-on:click="btnClose">{{ $t('navigation.managementProxyCirculars.name') }}</NuxtLink>
                      </li>
                      <li class="item34 last rank4">
                        <NuxtLink :to="localePath('investorRelations-financialDocuments-annualInformationForms')" v-on:click="btnClose">{{ $t('navigation.annualInformationForms.name') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li :class="[{ open: openShareholder }, 'item36 rank1 parent']">
                  <NuxtLink :to="localePath('investorRelations-shareholderInformation')" @click="openShareholder = !openShareholder">{{ $t('navigation.shareholderInformation.name') }}</NuxtLink>
                  <div class="itemChildDiv36">
                    <ul class="itemChild36">
                      <li class="item60 first rank0">
                        <NuxtLink :to="localePath('investorRelations-stockChart')" v-on:click="btnClose">{{ $t('navigation.stockChart.name') }}</NuxtLink>
                      </li>
                      <li class="item37 rank1">
                        <NuxtLink :to="localePath('investorRelations-transfertAgentAuditor')" v-on:click="btnClose">{{ $t('navigation.transfertAgentAuditor.name') }}</NuxtLink>
                      </li>
                      <li class="item38 rank2">
                        <NuxtLink :to="localePath('investorRelations-transfertAgentAuditor')" v-on:click="btnClose">{{ $t('navigation.transfertAgentAuditor.name') }}</NuxtLink>
                      </li>
                      <li class="item39 rank3">
                        <NuxtLink :to="localePath('investorRelations-faq')" v-on:click="btnClose">{{ $t('navigation.faq.name') }}</NuxtLink>
                      </li>
                      <li class="item40 last rank4">
                        <NuxtLink :to="localePath('investorRelations-irContact')" v-on:click="btnClose">{{ $t('navigation.irContact.name') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="item42 rank2 parent">
                  <NuxtLink :to="localePath('investorRelations-corporateGovernance')">{{ $t('navigation.corporateGovernance.name') }}</NuxtLink>
                  <div class="itemChildDiv42">
                    <ul class="itemChild42">
                      <li class="item71 first last rank0">
                        <NuxtLink :to="localePath('investorRelations-governanceCommitteeCharter')" v-on:click="btnClose">{{ $t('navigation.governanceCommitteeCharter.name') }}</NuxtLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="item58 last rank3">
                  <NuxtLink :to="localePath('investorRelations-emailAlerts')" v-on:click="btnClose">{{ $t('navigation.emailAlerts.name') }}</NuxtLink>
                </li>
              </ul>
            </div>
          </li>
          <li class="item55 rank4">
            <NuxtLink :to="localePath('sustainability-sustainableDevelopment')" v-on:click="btnClose">{{ $t('navigation.sustainability.name') }}</NuxtLink>
          </li>
          <li class="item46 last rank5">
            <NuxtLink :to="localePath('contact')" v-on:click="btnClose">{{ $t('navigation.contact.name') }}</NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>
